import { Icons } from "@flash-tecnologia/hros-web-ui-v2";
import { MainContainer, Container, Number } from "./styles";
import { MouseEventHandler } from "react";

interface BellNotificationProps {
  value?: number;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

const BellNotification = ({ value, onClick }: BellNotificationProps) => {
  return (
    <MainContainer onClick={(e) => (onClick ? onClick(e) : null)}>
      {value ? <Number>{value}</Number> : null}
      <Container>
        <Icons
          name="IconMail"
          size={16}
          fill="none"
          color="var(--color-neutral-50)"
        />
      </Container>
    </MainContainer>
  );
};

export default BellNotification;
