import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

const MainContainer = styled.div<{ visualized?: boolean }>`
  @keyframes colorTransition {
    from {
      background-color: var(--color-secondary-light1);
      box-shadow: 0px 0px 10px 1px rgba(254, 43, 143, 0.16);
      border-color: var(--color-secondary-light4);
    }
    to {
      background-color: var(--color-neutral-pure);
      box-shadow: none;
      border-color: var(--color-neutral-light2);
    }
  }

  display: flex;
  align-items: flex-start;
  justify-content: center;
  border: 1px solid var(--color-neutral-light2);
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 12px;

  animation-name: ${({ visualized }) =>
    !visualized ? "colorTransition" : undefined};
  animation-duration: ${({ visualized }) => (!visualized ? "4s" : 0)};

  :hover {
    background-color: var(--color-neutral-light1);
    border-color: var(--color-neutral-light3);
  }
`;

const IconContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: auto;
  padding-right: 5px;
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 10px;
`;

const DateContainer = styled(ButtonContainer)`
  cursor: default;
  margin-top: 0px;
`;

const Title = styled(Typography)`
  color: var(--color-neutral-dark3);
  font-weight: 700 !important;
  word-break: break-word;
`;

const Date = styled(Typography)`
  color: var(--color-neutral-dark5);
  font-weight: 600 !important;
`;

const Text = styled(Typography)`
  color: var(--color-neutral-dark4);
  font-weight: 600 !important;
  word-break: break-word;
`;

const TextWithHtml = styled.div`
  color: var(--color-neutral-dark4);
  font-size: calc(1rem * 0.875);
  width: 100%;

  ul,
  ol {
    padding-left: 0.5em;
  }

  ul li,
  ol li {
    list-style-position: outside;
    margin-left: 1em;
  }
`;

const Button = styled(Typography)`
  color: var(--color-secondary-dark5);
  text-transform: lowercase;
  font-weight: 700 !important;
  margin-right: 2px;

  ::first-letter {
    text-transform: capitalize;
  }
`;

const Space = styled.div`
  display: inline;
  height: 5px;
  width: 5px;
  border-radius: 100%;
  background-color: var(--color-neutral-dark5);
  margin: 0 5px;
`;

export {
  Button,
  ButtonContainer,
  ContentContainer,
  Date,
  DateContainer,
  IconContainer,
  MainContainer,
  Space,
  Text,
  TextWithHtml,
  Title,
};
