import {
  Container,
  Header,
  ListContainer,
  PinOnHomeContainer,
  PinOnHomeText,
  ProgressCircle,
} from "./styles";
import { Step, StepBlocked, StepDone } from "../StepItem";
import {
  Icons,
  LinkButton,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { getFromLS } from "@flash-tecnologia/hros-web-utility";
import { OnboardingStep, useOnboardingSteps } from "@flash-hros/hocs";
import { useNavigate } from "react-router-dom";
import { setEventTracking } from "@/utils";

type OnboardingStep = {
  id: string;
  title: string;
  path: string;
  done: boolean;
};

interface OnboardingStepsProps {
  onClose: () => void;
}

export const OnboardingSteps = ({ onClose }: OnboardingStepsProps) => {
  const navigate = useNavigate();
  const { steps, onboardingProgressPercentage, completeStep } =
    useOnboardingSteps();

  const userOnboardingStepsLS = getFromLS("userOnboardingSteps");
  const showPinOnHome =
    userOnboardingStepsLS?.isFixedOnHome !== undefined &&
    !userOnboardingStepsLS.isFixedOnHome;

  const onStepClick = (step: OnboardingStep) => {
    setEventTracking("header_onboarding_steps_item_clicked", {
      stepId: step.id,
      text: step.title,
    });
    if (!step.done) completeStep(step.id);

    onClose();
    navigate(step.path);
  };

  const pinOnboardingOnHome = () => {
    setEventTracking("header_onboarding_steps_menu_pin_home_clicked");
    dispatchEvent(new Event("pinOnboardingStepsOnHome"));
    onClose();
  };

  return (
    <Container>
      {showPinOnHome && (
        <PinOnHomeContainer>
          <LinkButton variant="default" onClick={pinOnboardingOnHome}>
            <PinOnHomeText variant="caption" style={{ fontWeight: 700 }}>
              <Icons name="IconPin" size={16} style={{ marginRight: 2 }} />
              Fixar Primeiros passos na página inicial
            </PinOnHomeText>
          </LinkButton>
        </PinOnHomeContainer>
      )}
      <Header>
        <div style={{ flexGrow: 1 }}>
          <Typography
            variant="headline9"
            style={{ color: "var(--color-neutral-30)" }}
          >
            Primeiros passos
          </Typography>
          <Typography
            variant="body4"
            style={{ color: "var(--color-neutral-50)", fontWeight: 600 }}
          >
            Que tal começar por essas configurações?
          </Typography>
        </div>
        <ProgressCircle
          thickness={4}
          size={48}
          value={onboardingProgressPercentage}
        >
          <Icons
            name="IconRocket"
            size={24}
            color="var(--color-secondary-50)"
            style={{ flexShrink: 0 }}
          />
        </ProgressCircle>
      </Header>
      <ListContainer>
        {steps.map((step) => {
          const key = `onboarding-step-menu-item-${step.id}`;
          if (step.blocked) return <StepBlocked key={key} step={step} />;

          if (step.done)
            return (
              <StepDone
                key={key}
                step={step}
                onClick={() => onStepClick(step)}
              />
            );

          return (
            <Step key={key} step={step} onClick={() => onStepClick(step)} />
          );
        })}
      </ListContainer>
    </Container>
  );
};
