import styled from "styled-components";

const DotSpace = styled.div`
  display: inline;
  height: 5px;
  width: 5px;
  border-radius: 100%;
  background-color: var(--color-neutral-dark5);
  margin: 0 5px;
`;

export { DotSpace };
