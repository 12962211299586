import styled from "styled-components";

const MainContainer = styled.div<{ visible?: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  width: 492px;
  max-width: 100%;
  height: 100vh;
  box-shadow: 0px 12px 24px rgba(21, 25, 28, 0.1);
  background-color: #ffffff;
  padding-bottom: 40px;
  margin-right: ${({ visible }) => (visible ? 0 : "-493px")};
  transition: 0.4s;
  word-break: break-all;
  overflow-y: auto;
  z-index: 4;
`;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export { MainContainer, LoaderContainer };
