import { segmentEventTrack, segmentIdentifyTrack } from "./segment";

export const setEventTracking = (
  name: string,
  params?: Record<string, string>
) => {
  segmentEventTrack({
    name,
    params,
    module: "header",
    businessUnit: "platform",
  });
};

export const identify = (userId: string, params?: Record<string, string>) => {
  segmentIdentifyTrack({
    userId,
    params,
    module: "header",
    businessUnit: "platform",
  });
};
