import {
  usePermissions,
  useSelectedCompany,
} from "@flash-tecnologia/hros-web-utility";

interface UserPermissions {
  isAdmin: boolean;
  companies: CompanyWithPermissions[];
}

interface SelectedCompany {
  id: string;
  name: string;
  registrationNumber: string;
}

interface CompanyWithPermissions extends SelectedCompany {
  permissions: string[];
}

interface UserSession {
  permissions: UserPermissions;
  selectedCompany: SelectedCompany;
}

export const useSession = (): UserSession => {
  const permissions = usePermissions();
  const { selectedCompany } = useSelectedCompany();
  return {
    permissions,
    selectedCompany,
  };
};
