import { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  SelectedCompanyContainer,
  SelectedCompanyTitle,
  SelectedCompanyMenu,
  SelectCompanyMenuIcon,
  Divider,
} from "./styled";
import ActionItem from "./ActionItem";
import { Icons } from "@flash-tecnologia/hros-web-ui-v2";
import { ICompanyWithPermissions, ISelectedCompany } from "../types";
import { ActionListType, actionsList } from "./actions";
import { useLegacyPermissions } from "@flash-tecnologia/hros-web-utility";
import { useSession } from "../hooks/useSession";
import { useFlag } from "@flash-tecnologia/feature-flags";
import { MenuItem } from "@mui/material";
import { HeaderItem } from "./HeaderItem";
import { CompaniesList } from "./CompaniesList";

interface SelectCompanyMenuProps {
  companies: ICompanyWithPermissions[];
  selectedCompany?: ISelectedCompany;
  onChange: (company: ISelectedCompany) => void;
}

const SelectCompanyMenu = ({
  companies,
  selectedCompany,
  onChange,
}: SelectCompanyMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const { permissions } = useSession();
  const { checkPathPermission } = useLegacyPermissions();
  const isCompanyCreationEnabled = !useFlag({
    flagName: "FLASH_OS_FORCE_LEGACY_COMPANY_CREATION_UX",
  });

  const selectedCompanyPermissions = useMemo(() => {
    const { permissions: companyPermissions } =
      permissions.companies.find(
        (company) => company.id === selectedCompany?.id
      ) || {};
    return companyPermissions;
  }, [selectedCompany, permissions]);

  const companiesItems = useMemo(() => {
    return companies.map(({ id, name, registrationNumber }) => ({
      onClick: () => handleCompanySelection({ id, name, registrationNumber }),
      company: { id, name, registrationNumber },
    }));
  }, [companies]);

  const checkActionPermissions = (action: ActionListType) => {
    switch (action.key) {
      case "add-company":
        return canAddCompany();
      case "legacy-add-company":
        return canLegacyAddCompany(action.path);
      case "manage-companies":
        return canManageCompany();
      case "manage-admin":
        return canManageAdmin();
      case "manage-superadmin":
        return isSuperAdmin();
    }
  };

  const canManageAdmin = () => {
    return (
      selectedCompanyPermissions?.includes("core_manage_permissions") ||
      permissions?.isAdmin
    );
  };

  const canLegacyAddCompany = (actionPath: string) => {
    return checkPathPermission(actionPath) && !isCompanyCreationEnabled;
  };

  const canAddCompany = () => {
    return permissions?.isAdmin && isCompanyCreationEnabled;
  };

  const isSuperAdmin = () => {
    return permissions?.isAdmin;
  };

  const canManageCompany = () => {
    return (
      selectedCompanyPermissions?.includes("core_manage_company_settings") ||
      permissions?.isAdmin
    );
  };

  const closeMenu = () => setAnchorEl(null);

  const handleCompanySelection = async (company: ISelectedCompany) => {
    closeMenu();
    onChange(company);
  };

  const handleMenuButtonClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
    closeMenu();
  };

  const handleActionClick = (
    path: string,
    e: React.MouseEvent<HTMLElement>
  ) => {
    e.stopPropagation();
    e.preventDefault();
    closeMenu();
    navigate(path);
  };

  const filteredActions = useMemo(() => {
    return actionsList
      .filter((action) => checkActionPermissions(action))
      .map(({ key, label, icon, path }) => (
        <MenuItem
          key={`action-${key}`}
          onClick={(e) => handleActionClick(path, e)}
        >
          <ActionItem icon={icon} label={label} />
        </MenuItem>
      ));
  }, [permissions, selectedCompany, isCompanyCreationEnabled]);

  return (
    <div>
      <button onClick={handleMenuButtonClick}>
        <SelectedCompanyContainer>
          <Icons
            name="IconBuildingCommunity"
            size={16}
            color="var(--color-neutral-50)"
            style={{ flexShrink: 0 }}
          />
          <SelectedCompanyTitle
            variant="caption"
            variantColor="var(--color-neutral-50)"
            children={selectedCompany?.name}
          />
          {companies.length > 0 ? (
            <SelectCompanyMenuIcon
              name="IconCaretDownFilled"
              size={16}
              color="var(--color-neutral-50)"
              style={{ flexShrink: 0 }}
            />
          ) : null}
        </SelectedCompanyContainer>
      </button>

      <SelectedCompanyMenu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        <HeaderItem title={companies[0].name} />
        <Divider />
        <CompaniesList companies={companiesItems} />
        <Divider />
        {filteredActions}
      </SelectedCompanyMenu>
    </div>
  );
};

export default SelectCompanyMenu;
