import {
  Icons,
  ShapeIcon,
  ShapeIconProps,
  Tooltip,
} from "@flash-tecnologia/hros-web-ui-v2";
import { StepItem, StepText } from "./styles";

interface StepProps {
  step: {
    icon: ShapeIconProps["name"];
    title: string;
    requirementReason?: string;
  };
  onClick?: () => void;
}

export const Step = ({ step, onClick }: StepProps) => (
  <StepItem clickable onClick={onClick}>
    <ShapeIcon
      name={step.icon}
      variant="default"
      color="var(--color-secondary-50)"
      size={32}
      style={{ flexShrink: 0, cursor: "pointer" }}
    />
    <StepText variant="headline10">{step.title}</StepText>
    <Icons
      name="IconArrowRight"
      size={24}
      color="var(--color-secondary-50)"
      style={{ flexShrink: 0 }}
    />
  </StepItem>
);

export const StepDone = ({ step, onClick }: StepProps) => (
  <StepItem clickable inactive onClick={onClick}>
    <ShapeIcon
      name="IconCheck"
      variant="success"
      size={32}
      style={{
        flexShrink: 0,
        cursor: "pointer",
        border: "2px solid var(--color-feedback-success-70)",
      }}
    />
    <StepText variant="headline10" inactive>
      {step.title}
    </StepText>
  </StepItem>
);

export const StepBlocked = ({ step }: StepProps) => (
  // The span tag inside the Tooltip component is
  // because the child of a Material-UI Tooltip
  // must be able to hold a ref.
  <StepItem inactive>
    <ShapeIcon
      name="IconLock"
      variant="neutral"
      color="var(--color-neutral-40)"
      size={32}
      style={{ flexShrink: 0 }}
    />
    <StepText variant="headline10" inactive>
      {step.title}
    </StepText>
    <Tooltip title={step.requirementReason}>
      <span>
        <Icons
          name="IconInfoCircle"
          size={16}
          color="var(--color-neutral-40)"
          style={{ flexShrink: 0 }}
        />
      </span>
    </Tooltip>
  </StepItem>
);
