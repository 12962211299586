import { Icons, ShapeIcon } from "@flash-tecnologia/hros-web-ui-v2";
import {
  OnboardingStepsContainer,
  OnboardingStepsMenu,
  OnboardingStepTitle,
  ProgressBar,
} from "./styled";
import { OnboardingSteps } from "@/components/Header/UserOnboardingStepsMenu/components/OnboardingSteps";
import { Popover } from "@mui/material";
import { useOnboardingSteps } from "@flash-hros/hocs";
import { useState } from "react";
import { setEventTracking } from "@/utils";

const UserOnboardingStepsMenu = () => {
  const { loading, onboardingProgressPercentage } = useOnboardingSteps();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setEventTracking("header_onboarding_steps_menu_clicked");
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (loading) return null;

  return (
    <>
      <OnboardingStepsMenu
        aria-describedby="onboarding-steps-popover"
        onClick={handleClick}
        type="button"
      >
        <OnboardingStepsContainer>
          <ShapeIcon
            name="IconRocket"
            variant="default"
            color="var(--color-secondary-50)"
            size={24}
            style={{
              flexShrink: 0,
              cursor: "pointer",
            }}
          />
          <OnboardingStepTitle
            variant="caption"
            children={`Primeiros passos: ${onboardingProgressPercentage}%`}
          />
          <Icons
            name="IconChevronDown"
            color="var(--color-secondary-50)"
            size={16}
          />
        </OnboardingStepsContainer>
        <ProgressBar
          variant="determinate"
          value={onboardingProgressPercentage}
        />
      </OnboardingStepsMenu>
      <Popover
        id="onboarding-steps-popover"
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        PaperProps={{
          sx: {
            borderRadius: (theme) => theme.borders.radius.s,
            boxShadow: "0px 4px 26px 0px rgba(0, 0, 0, 0.1)",
          },
        }}
      >
        <OnboardingSteps onClose={handleClose} />
      </Popover>
    </>
  );
};

export { UserOnboardingStepsMenu };
