import { memo } from "react";
import { Notification } from "../Notification";
import { NotificationListProps } from "./types";

const NotificationList = memo(
  ({ notifications, onSubmit }: NotificationListProps) => {
    return (
      <>
        {notifications?.map((notification, i) => (
          <Notification
            key={"header-notification-" + i}
            {...notification}
            onSubmit={onSubmit}
          />
        ))}
      </>
    );
  }
);

export { NotificationList };
