import styled from "styled-components";
import { Tag as DSTag, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { Menu, MenuItem } from "@mui/material";

export const StyledMenu = styled(Menu)`
  && {
    top: 7px;

    & > .MuiPaper-root {
      width: 260px;
      box-shadow: 0px 4px 26px 0px #0000001a;
    }

    ul {
      &.MuiList-root {
        padding: 0;
        li {
          padding: 18px 20px;

          &.MuiButtonBase-root {
            &:hover {
              color: ${({ theme }) => theme.colors.secondary[50]};
              background-color: ${({ theme }) => theme.colors.secondary[90]};
            }
          }
        }
      }
    }
  }
`;

export const AccessItem = styled.div`
  padding: 12px 16px 16px;
`;

export const ChangeAccessText = styled(Typography)`
  display: flex;
  align-items: center;
  gap: 2px;
`;

export const EmailText = styled(Typography)`
  margin-bottom: 12px;
`;

export const Item = styled(MenuItem)`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 20px;
  border-top: 1px solid ${({ theme }) => theme.colors.neutral[90]};
`;

export const Tag = styled(DSTag)`
  padding: 4px 8px;
  gap: ${({ theme }) => theme.spacings.xs5};

  && svg {
    background-color: unset;
  }
`;
