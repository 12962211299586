export default {
  translations: {
    header: {
      performanceText: "completed profile",
    },
    notification: {
      title: "Notifications",
      firstTab: "My company",
      secondTab: "My app",
      emptyMessageTitle: "You don't have any notifications",
      emptyMessageText: "Your notifications will be displayed here.",
      detailButton: "See details",
      detailHeaderTitle: "Communication details",
      image: "Imagem da notificação",
      reactButtonLabel: "React",
      reaction: {
        default: "React",
        heart: "Loved",
        like: "Liked",
        support: "Support",
        congrats: "Congratulations",
      },
      research: "Pesquisa",
      researchSendButton: "Enviar",
      researchButtonLabel: "Answer",
      researchButtonLabelAnswered: "See answers",
      researchPlaceholder: "Deixar comentário",
      researchAlertToast: "Deixe sua reação para progresseguir",
      researchErrorToast: "Deixe sua reação para progresseguir",
      researchModal: {
        title: "Eba, valeu por compartilhar sua opinião!",
        principalMessage: "Suas respostas são super importantes para nós",
        secondaryMessage:
          "Quando você registra seu feedback, aprendemos muito e isso nos ajuda a buscar melhorias e novas formas para garantir seu bem-estar.",
        button: "Ir para a página inicial",
      },
      anonymousTag: "Resposta anônima",
      anonymousInfo:
        "Sua resposta é completamente anônima, sua identidade não será registrada.",
      deadlineAlert: {
        title: "Essa pesquisa está encerrada",
        description:
          "Não foi possível exibir essa pesquisa, pois ela está encerrada e não receberá mais respostas.",
      },
    },
  },
};
