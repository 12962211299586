import { CircularProgress, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.neutral[100]};
  max-height: 480px;
  max-width: 400px;
`;

const PinOnHomeContainer = styled.div`
  padding: ${({ theme }) => `${theme.spacings.xs1} ${theme.spacings.xs}`};
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[90]};
`;

const PinOnHomeText = styled(Typography)`
  display: flex;
  align-items: center;
  font-weight: 700;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs3};
  padding: ${({ theme }) =>
    `${theme.spacings.xs} ${theme.spacings.xs} ${theme.spacings.xs3}`};
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs3};
  padding: ${({ theme }) =>
    `${theme.spacings.xs3} ${theme.spacings.xs} ${theme.spacings.xs}`};
  overflow: auto;
`;

const ProgressCircle = styled(CircularProgress)`
  && .circle-background {
    color: ${({ theme }) => theme.colors.secondary[90]};
  }
`;

export {
  Container,
  Header,
  ListContainer,
  ProgressCircle,
  PinOnHomeContainer,
  PinOnHomeText,
};
