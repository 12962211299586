import { ThemeProvider } from "@flash-tecnologia/hros-web-ui-v2";
import { BrowserRouter as Router } from "react-router-dom";
import Header from "../src/components/Header";
import { HeaderRender } from "@/utils";
import { ConfigurationContext } from "@/context";
import "../src/i18n";
import { FlagsProvider } from "@flash-tecnologia/feature-flags";
import { useSession } from "./common/use-session";
import { ContractModal } from "./components/Modals";

export default function Root() {
  const { companyId, userId, economicGroupId } = useSession();

  return (
    <FlagsProvider
      appName="hros-web-header"
      url={process.env.UNLEASH_BENEFITS_URL}
      token={process.env.UNLEASH_BENEFITS_PROXY_KEY}
      refreshIntervalSeconds={1800}
      initialContext={{
        userId,
        properties: {
          economicGroupId,
          companyId,
          employeeId: userId,
        },
      }}
    >
      <ConfigurationContext>
        <ThemeProvider>
          <Router>
            <HeaderRender>
              <Header />
              <ContractModal />
            </HeaderRender>
          </Router>
        </ThemeProvider>
      </ConfigurationContext>
    </FlagsProvider>
  );
}
