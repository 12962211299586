import { useEffect, useState } from "react";

import {
  StyledBoxContainer,
  StyledDiv,
  StyledIcon,
  StyledLinkButton,
  StyledText,
  StyledTitle,
} from "./styled";

export const TrialCountdownTag = ({
  product,
  endDate,
  onClick,
  whenTimeOut,
}: {
  product: string;
  endDate: string;
  onClick: any;
  whenTimeOut: any;
}) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(endDate) - +new Date();

    let timeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };

    if (difference > 0) {
      timeLeft = {
        seconds: Math.floor((difference / 1000) % 60),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        days: Math.floor(difference / (1000 * 3600 * 24)),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [showButton, setShowButton] = useState(false);

  const timeRemaining = timeLeft?.days || timeLeft?.hours || timeLeft?.minutes;

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  useEffect(() => {
    if (endDate != "" && !timeRemaining) {
      whenTimeOut();
    }
  }, [timeRemaining]);

  useEffect(() => {
    if (endDate != "") {
      const difference = +new Date(endDate) - +new Date();
      if (difference <= 0) {
        whenTimeOut();
      }
    }
  }, [endDate]);

  return (
    <StyledBoxContainer active={!!timeRemaining}>
      <StyledTitle
        active={!!timeRemaining}
        style={{ fontWeight: 600 }}
        variant="body4"
      >
        {product}
      </StyledTitle>
      <div onMouseLeave={() => setShowButton(false)}>
        {showButton && (
          <StyledLinkButton variant="default" onClick={onClick}>
            <StyledText variant="body4" style={{ fontWeight: 700 }}>
              Contratar agora
            </StyledText>

            <StyledIcon
              name="IconArrowRight"
              fill="transparent"
              color="#C8195A"
              size={16}
            />
          </StyledLinkButton>
        )}
      </div>
      {!showButton && (
        <StyledDiv>
          <StyledTitle
            active={!!timeRemaining}
            variant="body3"
            style={{ fontWeight: 700 }}
            onMouseEnter={() => setShowButton(!timeRemaining)}
            onMouseLeave={() => setShowButton(false)}
          >
            <span>{timeLeft.days}d</span> <span>{timeLeft.hours}h</span>{" "}
            <span>{timeLeft.minutes}m</span>
          </StyledTitle>
          <StyledIcon
            name="IconArrowRight"
            fill="transparent"
            style={{ display: timeRemaining ? "none" : "flex" }}
            color={timeRemaining ? "#C8195A" : "#83727D"}
            size={16}
          />
        </StyledDiv>
      )}
    </StyledBoxContainer>
  );
};
