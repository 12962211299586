import {
  MonitoringErrorBoundary,
  MonitoringManager,
} from "@flash-tecnologia/hros-web-utility";
import { MonitoringLevel } from "@flash-tecnologia/hros-web-utility/dist/monitoring/MonitoringLevel";

export const projectDsn =
  "https://a7b5c8592ee9b29b3ebc9e2b20db5ae8@o266934.ingest.sentry.io/4505682778128384";

export class ErrorBoundary extends MonitoringErrorBoundary {
  project: string = projectDsn;

  constructor(props) {
    super(props);
  }

  static captureException(
    error: Error,
    severity?: MonitoringLevel,
    extras?: Record<string, any>
  ) {
    MonitoringManager.captureException(projectDsn, error, severity, extras);
  }
  componentDidCatch(error: any) {
    MonitoringManager.captureException(this.project, error);
  }
}
