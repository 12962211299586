import {
  Icons,
  LinkButton,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const StyledBoxContainer = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: ${({ active }) =>
    active ? "rgba(254, 43, 143, 0.08)" : "none"};
  border-radius: 4px;
  border: ${({ active }) => (active ? "none" : "1px solid #EBE6E9")};
  margin-left: 12px;
  width: 306px;
  height: 40px;
`;

export const StyledTitle = styled(Typography)<{ active: boolean }>`
  color: ${({ theme, active }) =>
    active ? theme.colors.secondary[50] : theme.colors.neutral[50]};
`;

export const StyledLinkButton = styled(LinkButton)``;

export const StyledText = styled(Typography)`
  color: ${({ theme }) => theme.colors.secondary[50]};
`;

export const StyledIcon = styled(Icons)``;

export const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
