import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

const StepItem = styled.div<{ clickable?: boolean; inactive?: boolean }>`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs3};
  align-items: center;
  height: 64px;
  padding: ${({ theme }) => theme.spacings.xs2};
  border-radius: ${({ theme }) => theme.borders.radius.s};
  background: ${({ theme, inactive }) =>
    inactive ? theme.colors.neutral[95] : theme.colors.secondary[99]};
  cursor: ${({ clickable }) => (clickable ? "pointer" : "default")};
`;

const StepText = styled(Typography)<{ inactive?: boolean }>`
  && {
    color: ${({ theme, inactive }) =>
      inactive ? theme.colors.neutral[40] : theme.colors.secondary[50]};
    flex-grow: 1;
    line-height: 116%;
  }
`;

export { StepText, StepItem };
