import { ShapeIcon, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { TitleContainer, Container, Title } from "./styled";

interface HeaderItemProps {
  title: string;
}

export const HeaderItem = ({ title }: HeaderItemProps) => {
  return (
    <Container>
      <TitleContainer>
        <ShapeIcon
          name="IconBuildingCommunity"
          variant="neutral"
          size={24}
          style={{ flexShrink: 0, padding: "5px" }}
        />
        <Title
          variant="body4"
          variantColor="var(--color-neutral-40)"
          weight={700}
        >
          {title}
        </Title>
      </TitleContainer>
      <Typography variant="caption" variantColor="var(--color-neutral-40)">
        Empresas que você gerencia nesse grupo
      </Typography>
    </Container>
  );
};
