import { Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { Container } from "./styled";

const ActionItem = ({ label, icon }) => {
  return (
    <Container>
      <Icons
        name={icon}
        size={16}
        fill="transparent"
        style={{ flexShrink: 0 }}
      />
      <Typography
        variant="body4"
        weight={600}
        children={label}
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      />
    </Container>
  );
};

export default ActionItem;
