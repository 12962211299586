import styled from "styled-components";
import { Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { Menu } from "@mui/material";

const SelectCompanyMenuIcon = styled(Icons)`
  svg {
    fill: none;
    stroke: ${({ theme }) => theme.colors.neutral[40]};
  }
`;

const SelectedCompanyContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 240px;
  padding: 8px;
  border-radius: 10px;
  cursor: pointer;

  text-align: center;

  &:hover {
    opacity: 0.5;
  }
`;

const SelectedCompanyTitle = styled(Typography)`
  && {
    display: flex;
    display: -webkit-box;
    line-height: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 4px 0 8px;
    font-weight: 700;
  }
`;

const SelectedCompanyMenu = styled(Menu)`
  && {
    top: 7px;

    & .MuiPaper-root {
      max-width: 280px;
      box-shadow: 0px 4px 26px 0px #0000001a;
    }

    ul {
      &.MuiList-root {
        padding: 12px;
        border-radius: 8px;

        li {
          display: flex;
          padding: 0px;
          border-radius: 8px;
          color: ${({ theme }) => theme.colors.neutral[30]};

          &.Mui-selected {
            color: var(--color-primary);
            background-color: var(--color-secondary-light2);
            border-radius: 8px;
          }

          &.MuiButtonBase-root {
            div {
              text-align: start;
            }
            &:active {
              background-color: ${({ theme }) => theme.colors.secondary[90]};
              border-radius: 8px;
            }
            &:hover {
              color: ${({ theme }) => theme.colors.secondary[50]};
              background-color: ${({ theme }) => theme.colors.secondary[90]};
              border-radius: 8px;
            }
            &:last-child {
              margin-bottom: 0px;
              border-bottom: none;
            }
          }
        }
      }
    }
  }
`;

const Divider = styled.div`
  margin-bottom: 20px;
  width: 100%;
`;

export {
  Divider,
  SelectedCompanyContainer,
  SelectCompanyMenuIcon,
  SelectedCompanyMenu,
  SelectedCompanyTitle,
};
