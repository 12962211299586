import { GenericProfilePicture } from "@flash-tecnologia/hros-web-ui-v2";
import { IconContainer } from "./styled";

interface CompanyIconProps {
  companyName: string;
}

const CompanyIcon = ({ companyName }: CompanyIconProps) => {
  return (
    <IconContainer>
      <GenericProfilePicture name={companyName} size={32} />
    </IconContainer>
  );
};

export default CompanyIcon;
