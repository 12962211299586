import {
  Icons,
  LinkButton,
  ShapeIcon,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { BaseModal } from "../../BaseModal";
import {
  FaqContainer,
  SpacedContainer,
  StyledPDFViewer,
} from "../contract-modal.styles";
import { useState } from "react";
import { setEventTracking } from "@/utils";
import { Amendment } from "../contract-modal";

interface AmendmentFlowProps {
  open: boolean;
  loading: boolean;
  amendments: Amendment[];
  onSign: () => void;
  onClose: () => void;
}

type Steps = "intro" | "contract";

export const AmendmentFlow = ({
  open,
  loading,
  amendments,
  onSign,
  onClose,
}: AmendmentFlowProps) => {
  const [step, setStep] = useState<Steps>("intro");
  const [selectedAmendment, setSelectedAmendment] = useState<Amendment>();

  const isManyAmendments = amendments.length > 1;

  const handleLeftButton = () => {
    if (step === "intro") {
      setEventTracking("contract_amendment_modal_pdf_view_click", {
        product: amendments[0].type,
      });
      setSelectedAmendment(amendments[0]);
      setStep("contract");
    } else {
      setStep("intro");
      setSelectedAmendment(null);
    }
  };

  const onAmendmentClick = (amendment: Amendment) => {
    setSelectedAmendment(amendment);
    setStep("contract");
  };

  const onModalClose = async () => {
    await onSign();
    onClose();
  };

  const backText = {
    intro: "Ver contrato",
    contract: "Voltar",
  }[step];

  return (
    <BaseModal
      open={open}
      onClose={onModalClose}
      onNext={onModalClose}
      onNextText={
        <>
          Ok, estou ciente! <Icons name="IconCheck" />
        </>
      }
      onBackText={backText}
      onBack={
        !isManyAmendments || step === "contract" ? handleLeftButton : undefined
      }
      loading={loading}
    >
      {step === "intro" && (
        <>
          <SpacedContainer>
            <ShapeIcon
              name="IconWritingSign"
              variant="default"
              size={48}
              color="var(--color-secondary-50)"
            />
            <Typography variant="headline8">
              Atualizamos nossos contratos de prestação de serviços!
            </Typography>
          </SpacedContainer>
          <Typography variant="body4" variantColor="var(--color-neutral-40)">
            Como você já deve saber, a Flash agora é parceira da Visa, líder
            global de pagamentos digitais. A parceria foi firmada após nossa
            autorização como Instituição de Pagamento, pelo Banco Central. Com
            isso vamos começar a emitir nossos próprios cartões!
          </Typography>
          <Typography variant="body4" variantColor="var(--color-neutral-40)">
            Essas duas mudanças marcam uma evolução não só na experiência de
            nossos clientes, como também para o negócio da Flash. Como
            consequência dessas mudanças o nosso contrato foi atualizado para
            refletir essas alterações.
          </Typography>
          <Typography variant="body4" variantColor="var(--color-neutral-40)">
            Os novos termos e condições visam atualizar, exclusivamente, o
            funcionamento do serviço de administração de cartões e contas de
            pagamento do seu cartão e não trará quaisquer impactos em sua
            experiência e tampouco alterações financeiras ou comerciais.
          </Typography>
          <Typography variant="body4" variantColor="var(--color-neutral-40)">
            Continuamos comprometidos em oferecer um serviço de excelência e
            qualidade!
          </Typography>
          <Typography variant="body4" variantColor="var(--color-neutral-40)">
            <strong>O que será preciso fazer? Nada.</strong> Basta continuar
            usando nossa plataforma e entenderemos que você está de acordo com
            essa atualização.
          </Typography>
          <Typography variant="body4" variantColor="var(--color-neutral-40)">
            Faz Flash e voa!
          </Typography>
          {isManyAmendments &&
            amendments.map((amendment) => (
              <LinkButton
                key={amendment.id}
                variant="default"
                onClick={() => onAmendmentClick(amendment)}
              >
                <Typography variant="caption" weight={700}>
                  Ver contrato de Cartão de{" "}
                  {amendment.type === "benefit" ? "Benefícios" : "Despesas"}
                </Typography>
              </LinkButton>
            ))}
        </>
      )}
      {step === "contract" && (
        <>
          <SpacedContainer>
            <Typography variant="headline8">
              Contrato de Prestação de Serviços
            </Typography>
            <Typography variant="body4" variantColor="var(--color-neutral-40)">
              Leia atentamente as condições comerciais do Contrato de Prestação
              de Serviços de{" "}
              {selectedAmendment.type === "benefit" ? "Benefícios" : "Despesas"}{" "}
              da Flash.
            </Typography>
          </SpacedContainer>
          <Typography
            variant="body4"
            weight={700}
            variantColor="var(--color-neutral-40)"
          >
            Leia abaixo
          </Typography>
          <StyledPDFViewer
            width="100%"
            height="280px"
            src={selectedAmendment.pdfUrl}
            options={{ showToolbar: true, fit: "height", page: 1 }}
          />
          {/* <FaqContainer>
            <ShapeIcon
              name="IconMessageCircle2"
              variant="default"
              size={32}
              color="var(--color-secondary-50)"
              style={{ flexShrink: 0 }}
            />
            <Typography
              variant="caption"
              weight={700}
              variantColor="var(--color-neutral-30)"
            >
              Possui alguma dúvida?
            </Typography>
            <LinkButton
              variant="primary"
              style={{ fontSize: 12, alignSelf: "center" }}
            >
              Acesse nosso FAQ
            </LinkButton>
          </FaqContainer> */}
        </>
      )}
    </BaseModal>
  );
};
