import styled, { css } from "styled-components";
import { Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";

export const CompanyItemContainer = styled.div<{ active?: boolean }>`
  padding: 12px 16px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid
    ${(props) =>
      props.active ? "transparent" : props.theme.colors.neutral[90]};
  background-color: ${(props) =>
    props.active ? props.theme.colors.secondary[90] : "none"};

  &:hover {
    border-color: transparent;

    p {
      color: ${({ theme }) => theme.colors.secondary[50]};
    }
    span {
      color: ${({ theme }) => theme.colors.secondary[50]};
    }
    svg {
      stroke: ${({ theme }) => theme.colors.secondary[50]};
      fill: transparent;
    }
  }
`;

export const DropdownOption = styled.div<{ active?: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;

  ${({ active }) =>
    active
      ? css`
          p,
          span,
          svg {
            color: ${({ theme }) => theme.colors.secondary[50]};
          }
        `
      : css`
          p {
            color: ${({ theme }) => theme.colors.neutral[30]};
          }
          svg {
            color: ${({ theme }) => theme.colors.secondary[40]};
          }
        `}
`;

export const CompanyNameRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const Label = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const CompanyName = styled(Typography)`
  display: flex;
  display: -webkit-box;
  line-height: 1;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: wrap;
`;

export const ActiveIcon = styled(Icons)`
  color: ${({ theme }) => theme.colors.secondary[40]};
`;

export const RegistrationNumber = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
`;
