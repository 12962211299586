import { IContext } from "./types";

const initialState: IContext = {
  loading: { urls: [] },
  companies: [],
  notifications: [],
  newNotificationsCount: 0,
  notificationIdSelected: null,
  notificationDetail: {
    communication: {},
    count: {
      visualized: 0,
      liked: 0,
      loved: 0,
      congratulations: 0,
      support: 0,
    },
    reaction: 0,
    visualized: false,
  },

  dispatchAction: () => null,
};

export { initialState };
