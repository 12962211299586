enum EActions {
  SYSTEM_LOADING_START = "system_loading_start",
  SYSTEM_LOADING_END = "system_loading_end",
  GET_MY_COMPANIES = "get_my_companies",
  GET_MY_NOTIFICATIONS = "get_my_notifications",
  GET_MY_NEW_NOTIFICATIONS_COUNT = "get_my_new_notifications_count",
  GET_NOTIFICATION_DETAIL = "get_notification_detail",
  SET_NOTIFICATION_ID_SELECTED = "set_notification_id_selected",
}

interface IActions {
  type: EActions;
  payload?: any;
}

export { EActions, IActions };
