import { setInLS } from "@flash-tecnologia/hros-web-utility";
import { ISelectedCompany } from "../components/Header/types";
import { checkBenefitsAndRedirectHome } from "./checkBenefitsAndRedirect";

export interface IAccessToken {
  employeeId: string;
}

export const setCompanySelectedAndDispatchEvent = (
  company: ISelectedCompany
) => {
  checkBenefitsAndRedirectHome();
  const keyLS: string = "selectedCompany";
  setInLS({ key: keyLS, value: company });
  dispatchEvent(
    new CustomEvent("header_company_selected", {
      detail: {
        id: company.id,
        name: company.name,
        registrationNumber: company.registrationNumber,
      },
    })
  );
};
