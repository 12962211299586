import { Icons, TextField, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Container = styled.div`
  max-height: 270px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const SearchResultsText = styled(Typography)`
  text-align: center;
`;

export const SearchEmptyState = styled.div`
  max-width: 260px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  text-align: center;
`;

export const SearchInputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  background-color: transparent;
  height: 48px;
  width: 100%;
  border: ${(props) => props.theme.borders.width.xs2} solid
    ${(props) => props.theme.colors.neutral[90]};
  border-radius: 150px;
  &:before,
  &:after {
    content: none;
  }
`;

export const SearchIcon = styled(Icons)`
  margin-left: 16px;
  fill: transparent;
  circle,
  line {
    stroke: ${(props) => props.theme.colors.neutral[70]};
  }
`;

export const SearchInput = styled(TextField)`
  width: 100%;
  color: ${(props) => props.theme.colors.neutral[30]};
  font-weight: 600;

  & .MuiInputBase-root {
    border: none !important;
  }

  & .MuiInputBase-input {
    padding: 14px 0;
  }
`;
