import { useUpdateFlagsContext } from "@flash-tecnologia/feature-flags";
import { useSession } from "./use-session";
import { useEffect } from "react";

export const useUpdateFeatureFlags = () => {
  const { userId, companyId, economicGroupId } = useSession();
  const updateContext = useUpdateFlagsContext();

  useEffect(() => {
    updateContext(userId, {
      economicGroupId,
      companyId,
      employeeId: userId,
    });
  }, [userId, companyId]);
};
