import { Axios } from "@flash-tecnologia/hros-web-utility";
import { dispatchToast } from "@/utils";

export async function getContractByCompanyAndType(input: {
  companyId: string;
  type?: string;
}) {
  try {
    const {
      data: { contracts },
    } = await Axios({
      service: "contractManager",
      method: "get",
      url: `v2/contracts?companyId=${input.companyId}`,
    });

    if (!input.type) return contracts;

    return contracts.find((contract) => {
      return contract.type === input.type;
    });
  } catch {}
}

export async function getAmendmentSignedUrl(input: {
  contractId: string;
  amendmentId: string;
}) {
  try {
    const { data } = await Axios({
      service: "contractManager",
      method: "get",
      url: `v2/contracts/${input.contractId}/amendment/${input.amendmentId}/get-signed-url`,
    });
    return data;
  } catch {}
}

export async function getContractSignedUrl(input: { contractId: string }) {
  try {
    const { data } = await Axios({
      service: "contractManager",
      method: "get",
      url: `v2/contracts/${input.contractId}/get-signed-url`,
    });
    return data;
  } catch {}
}

export async function signAmendment(input: {
  contractId: string;
  amendmentId: string;
}) {
  try {
    await Axios({
      service: "contractManager",
      method: "post",
      url: `v2/contracts/${input.contractId}/amendment/${input.amendmentId}/sign`,
    });
  } catch (err) {
    dispatchToast({
      type: "error",
      content: "Ocorreu um erro. Por favor, tente novamente.",
    });
    throw err;
  }
}

export async function signContract(input: { contractId: string }) {
  try {
    await Axios({
      service: "contractManager",
      method: "post",
      url: `v2/contracts/${input.contractId}/sign`,
    });
  } catch (err) {
    dispatchToast({
      type: "error",
      content: "Ocorreu um erro. Por favor, tente novamente.",
    });
    throw err;
  }
}
