import { EActions, IActions } from "./actions";
import { initialState } from "./store";
import { IContext } from "./types";

const reducer = (state: IContext, action: IActions) => {
  switch (action.type) {
    case EActions.SYSTEM_LOADING_START:
      return {
        ...state,
        loading: {
          urls: []
            .concat(state.loading.urls, [action.payload])
            .filter((u) => u),
        },
      };
    case EActions.SYSTEM_LOADING_END:
      return {
        ...state,
        loading: {
          urls: state.loading.urls.filter((u) => u !== action.payload),
        },
      };
    case EActions.GET_MY_COMPANIES:
      return {
        ...state,
        companies: [...action.payload],
      };
    case EActions.GET_MY_NOTIFICATIONS:
      return {
        ...state,
        notifications: [...action.payload],
      };
    case EActions.GET_MY_NEW_NOTIFICATIONS_COUNT:
      return {
        ...state,
        newNotificationsCount: action.payload,
      };
    case EActions.GET_NOTIFICATION_DETAIL:
      return {
        ...state,
        notificationDetail: action.payload,
      };
    case EActions.SET_NOTIFICATION_ID_SELECTED:
      return {
        ...state,
        notificationIdSelected: action.payload,
      };
    default:
      throw new Error("Error in context reducer");
  }
};

export { initialState, reducer };
