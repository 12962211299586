import { Icons } from "@flash-tecnologia/hros-web-ui-v2";
import {
  ContainerStyled,
  HeaderStyled,
  HeaderText,
  TitleText,
  ContentText,
  IconContainer,
  CardContainer,
  Divisor,
} from "./styled";
import { AccessSelection } from "@flash-hros/authentication";

interface ChangeCompanyProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  onAccessSelected?: () => void;
  onClose?: () => void;
}

export const ChangeCompany = ({
  visible,
  setVisible,
  onAccessSelected,
  onClose,
}: ChangeCompanyProps) => {
  return (
    <ContainerStyled visible={visible}>
      <HeaderStyled>
        <HeaderText>
          <TitleText variant="headline7">Acesse outras contas Flash</TitleText>
          <ContentText variant="body4" weight={400}>
            Veja abaixo as organizações em que você possui uma conta de acesso
            ao app e plataforma Flash.
          </ContentText>
        </HeaderText>
        <IconContainer
          onClick={() => {
            if (visible && onClose) onClose();
            setVisible(!visible);
          }}
        >
          <Icons name="IconX" fill="#15191c" size={18} color={"#6b5b66"} />
        </IconContainer>
      </HeaderStyled>
      <CardContainer>
        <AccessSelection callback={onAccessSelected} />
      </CardContainer>
    </ContainerStyled>
  );
};
