import { PDFViewer } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const SpacedContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs4};
`;

export const StyledPDFViewer = styled(PDFViewer)`
  border-radius: ${({ theme }) => theme.borders.radius.xs};
  @media screen and (max-height: 750px) {
    height: 200px;
  }
`;

export const FaqContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs4};
  align-items: center;
  background: ${({ theme }) => theme.colors.secondary[99]};
  padding: ${({ theme }) => theme.spacings.xs3}
    ${({ theme }) => theme.spacings.xs2};
  border-radius: ${({ theme }) => theme.borders.radius.s};
`;
