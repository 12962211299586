import { Icons } from "@flash-tecnologia/hros-web-ui-v2";
import { Container } from "./styles";
import { IconProps } from "./types";

const Icon = ({
  backgroundColor,
  borderColor,
  size,
  onClick,
  ...rest
}: IconProps) => (
  <Container
    onClick={onClick}
    backgroundColor={backgroundColor}
    borderColor={borderColor}
  >
    <Icons size={size || 20} name="Add" {...rest} />
  </Container>
);

export { Icon };
