import { useEffect, useCallback, useMemo } from "react";
import { useNavigate, matchPath, useLocation } from "react-router-dom";
import { removeFromLS, removeFromSS } from "@flash-tecnologia/hros-web-utility";
import { Authentication } from "@flash-hros/auth-helper";
import { useRegisteredRoutes } from "../components/Header/hooks/useRegisteredRoutes";

const { CognitoSignOut } = Authentication;

const isPermissionsFreeRoute = (route: string) =>
  ["/home"].some((r) => {
    return route.startsWith(r);
  });

const HeaderRender = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const registeredRoutes = useRegisteredRoutes();

  const isHeaderDisabled = useMemo(() => {
    const isPermissionsFree = isPermissionsFreeRoute(location?.pathname);

    const registeredRoute = registeredRoutes.find((route) =>
      matchPath(route.path, location.pathname)
    );

    if (registeredRoute?.hideHeader) return true;

    if (isPermissionsFree) return false;

    return false;
  }, [location?.pathname, registeredRoutes]);

  const logoutHandler = useCallback(async () => {
    await CognitoSignOut({});
    removeFromLS({ key: "userAuth" });
    removeFromLS({ key: "hrosAccessToken" });
    navigate("authentication/login", { replace: true });
  }, [navigate]);

  useEffect(() => {
    window.addEventListener("logout", logoutHandler);

    return () => {
      window.removeEventListener("logout", logoutHandler);
    };
  }, []);

  return isHeaderDisabled ? null : <>{children}</>;
};

export default HeaderRender;
