import styled from "styled-components";
import { Typography } from "@flash-tecnologia/hros-web-ui-v2";

const Container = styled.header`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 50px 50px 30px 50px;

  > :last-child {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;

    :hover {
      border-color: var(--color-neutral-light3);
      background-color: var(--color-neutral-light1);
    }
  }
`;

const Title = styled(Typography)`
  color: var(--color-neutral-dark1);
`;

export { Container, Title };
