import { Tab, TabProps, ThemeProvider } from "@flash-tecnologia/hros-web-ui-v2";
import { Container, TabContainer } from "./styles";

const Tabs = ({ tabItens, ...rest }: TabProps) => (
  <ThemeProvider>
    <Container>
      <Tab
        tabItens={tabItens?.map(({ label, component }) => ({
          label,
          component: <TabContainer>{component}</TabContainer>,
        }))}
        {...rest}
      />
    </Container>
  </ThemeProvider>
);

export { Tabs };
