export type ActionListKeys =
  | "add-company"
  | "legacy-add-company"
  | "manage-admin"
  | "manage-superadmin"
  | "manage-companies";

export type ActionListType = {
  key: ActionListKeys;
  label: string;
  icon: string;
  path: string;
};

export const actionsList: ActionListType[] = [
  {
    key: "add-company",
    label: "Adicionar empresa",
    icon: "IconPlus",
    path: "/settings/add-company",
  },
  {
    key: "legacy-add-company",
    label: "Cadastrar empresa",
    icon: "IconCirclePlus",
    path: "/benefits/dashboard/company/register",
  },
  {
    key: "manage-companies",
    label: "Gerenciar empresas",
    icon: "IconSettings",
    path: "/settings/",
  },
  {
    key: "manage-admin",
    label: "Gerenciar administradores",
    icon: "IconKey",
    path: "/settings/permissions",
  },
  {
    key: "manage-superadmin",
    label: "Gerenciar super administradores",
    icon: "IconShieldCheck",
    path: "/settings/permissions/administrators",
  },
];
