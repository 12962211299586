import { useCallback, useEffect, useState } from "react";

const REGISTER_ROUTE_EVENT = "registerRoutes";

export const useRegisteredRoutes = () => {
  const [routes, setRoutes] = useState<Route[]>([]);

  const onRegisteredRouteEvent = useCallback(
    ({ detail: routesToBeRegistered }: CustomEvent<Route[]>) => {
      const newRoutes = routesToBeRegistered.filter(
        (routeToBeRegistered) =>
          !routes.some((r) => r.path === routeToBeRegistered.path)
      );
      setRoutes((state) => [...state, ...newRoutes]);
    },
    [routes, setRoutes]
  );

  useEffect(() => {
    window.addEventListener(
      REGISTER_ROUTE_EVENT,
      onRegisteredRouteEvent as EventListener
    );
    return () => {
      window.removeEventListener(
        REGISTER_ROUTE_EVENT,
        onRegisteredRouteEvent as EventListener
      );
    };
  }, []);

  return routes;
};

export type Route = {
  path: string;
  hideHeader: boolean;
};
