import styled from "styled-components";

const Container = styled.div`
  padding: 0 50px;
  width: 100%;
`;

const TabContainer = styled.div`
  width: 100%;
  margin-top: 30px;
`;

export { Container, TabContainer };
