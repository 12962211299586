import { Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { BaseModal } from "../../BaseModal";
import { SpacedContainer, StyledPDFViewer } from "../contract-modal.styles";

interface ContractFlowProps {
  open: boolean;
  loading: boolean;
  onSign: () => void;
  pdfUrl: string;
}

export const ContractFlow = ({
  open,
  loading,
  pdfUrl,
  onSign,
}: ContractFlowProps) => {
  return (
    <BaseModal
      open={open}
      onNext={onSign}
      onNextText={
        <>
          Declaro que li e aceito <Icons name="IconCheck" />
        </>
      }
      loading={loading}
    >
      <SpacedContainer>
        <Typography variant="headline8">
          Contrato de Prestação de Serviços
        </Typography>
        <Typography variant="body4" variantColor="var(--color-neutral-40)">
          Para finalizar sua contratação, você deve ler atentamente e aceitar as
          condições comerciais do Contrato de Prestação de Serviços da Flash.
          <br />O uso da plataforma só é liberado após o aceite.
        </Typography>
      </SpacedContainer>
      <Typography
        variant="body4"
        weight={700}
        variantColor="var(--color-neutral-40)"
      >
        Leia abaixo
      </Typography>
      <StyledPDFViewer
        width="100%"
        height="400px"
        src={pdfUrl}
        options={{ showToolbar: true, fit: "height", page: 1 }}
      />
    </BaseModal>
  );
};
