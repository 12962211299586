import { Tooltip } from "@flash-tecnologia/hros-web-ui-v2";
import CompanyIcon from "./CompanyIcon";
import {
  Label,
  DropdownOption,
  ActiveIcon,
  CompanyItemContainer,
  RegistrationNumber,
  CompanyNameRow,
  CompanyName,
} from "./styled";
import { cnpjFormat } from "@/utils";

interface CompanyItemPropType {
  active: boolean;
  registrationNumber: string;
  name: string;
}

const MAX_NAME_LENGTH = 58;

const CompanyItem = ({
  active,
  registrationNumber,
  name,
}: CompanyItemPropType) => {
  return (
    <CompanyItemContainer active={active}>
      <DropdownOption active={active}>
        <CompanyIcon companyName={name} />
        <Label>
          <Tooltip
            title={name}
            placement="right"
            // We force to not show the tooltip if the name is not long
            {...(name.length <= MAX_NAME_LENGTH && { open: false })}
          >
            <CompanyNameRow>
              <CompanyName variant="body4" weight={700} children={name} />
              {active && (
                <ActiveIcon
                  name="IconCheck"
                  size={24}
                  style={{ flexShrink: 0 }}
                />
              )}
            </CompanyNameRow>
          </Tooltip>
          <RegistrationNumber
            variant="caption"
            weight={600}
            children={`CNPJ ${cnpjFormat(registrationNumber)}`}
          />
        </Label>
      </DropdownOption>
    </CompanyItemContainer>
  );
};

export default CompanyItem;
