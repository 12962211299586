import { EActions, IActions } from "@/context/actions";
import { ErrorBoundary } from "@/utils";
import { Axios } from "@flash-tecnologia/hros-web-utility";
import { Dispatch } from "react";
import {
  NotificationDetailProps,
  NotificationProps,
} from "../components/NotificationFeed/types";

export async function getEmployeeNotifications({
  cursor,
  limit,
  dispatch,
  notifications,
  paginationMode,
}: {
  cursor: string | null;
  limit: number;
  dispatch: Dispatch<IActions>;
  notifications?: Array<NotificationProps>;
  paginationMode?: boolean;
}) {
  try {
    dispatch({
      type: EActions.SYSTEM_LOADING_START,
      payload: "getEmployeeNotifications",
    });

    const { data } = await Axios({
      service: "engagement",
      method: "get",
      url: `/v2/feeds/${cursor}/${limit}`,
      data: {},
    });

    const newNotifications =
      data?.value?.data?.map((notification) => {
        const { id } = notification;

        return { ...notification, _id: id };
      }) || [];

    dispatch({
      type: EActions.SYSTEM_LOADING_END,
      payload: "getEmployeeNotifications",
    });

    const newNotificationsFilter = newNotifications?.length
      ? newNotifications.filter(
          ({ _id: newNotificationId }) =>
            !notifications?.find(({ _id }) => _id === newNotificationId)
        )
      : [];

    const result = notifications?.length
      ? paginationMode
        ? [...notifications, ...newNotificationsFilter]
        : [...newNotificationsFilter, ...notifications]
      : newNotificationsFilter;

    dispatch({
      type: EActions.GET_MY_NOTIFICATIONS,
      payload: [...result],
    });

    return result;
  } catch (error) {
    ErrorBoundary.captureException(error);
    dispatch({
      type: EActions.SYSTEM_LOADING_END,
      payload: "getEmployeeNotifications",
    });

    dispatchEvent(
      new CustomEvent("showToast", {
        detail: {
          content: "Erro ao buscar suas notificações",
          type: "error",
        },
      })
    );

    throw new Error(error);
  }
}

export async function getEmployeeNotificationDetail({
  notificationId,
  dispatch,
}: {
  notificationId: string;
  dispatch: Dispatch<IActions>;
}) {
  try {
    dispatch({
      type: EActions.SYSTEM_LOADING_START,
      payload: "getEmployeeNotificationDetail",
    });

    const { data } = await Axios({
      service: "engagement",
      method: "get",
      url: `/v2/employees/communications/${notificationId}`,
      data: {},
    });

    dispatch({
      type: EActions.SYSTEM_LOADING_END,
      payload: "getEmployeeNotificationDetail",
    });

    dispatch({
      type: EActions.GET_NOTIFICATION_DETAIL,
      payload: data?.value,
    });

    return data?.value;
  } catch (error) {
    ErrorBoundary.captureException(error);
    dispatch({
      type: EActions.SYSTEM_LOADING_END,
      payload: "getEmployeeNotificationDetail",
    });

    dispatch({
      type: EActions.GET_NOTIFICATION_DETAIL,
      payload: {},
    });

    dispatchEvent(
      new CustomEvent("showToast", {
        detail: {
          content: "Erro ao buscar o detalhe da notificação",
          type: "error",
        },
      })
    );

    throw new Error(error);
  }
}

export function setNotificationDetail(
  dispatch: Dispatch<IActions>,
  notificationDetail: NotificationDetailProps
) {
  dispatch({
    type: EActions.GET_NOTIFICATION_DETAIL,
    payload: notificationDetail,
  });
}

export function setEmployeeNotifications(
  dispatch: Dispatch<IActions>,
  notifications: Array<NotificationProps>
) {
  dispatch({
    type: EActions.GET_MY_NOTIFICATIONS,
    payload: notifications,
  });
}

export function clearNotificationCount(dispatch: Dispatch<IActions>) {
  dispatch({
    type: EActions.GET_MY_NEW_NOTIFICATIONS_COUNT,
    payload: 0,
  });
}

export function clearNotifications(dispatch: Dispatch<IActions>) {
  dispatch({
    type: EActions.GET_MY_NOTIFICATIONS,
    payload: [],
  });

  dispatch({
    type: EActions.GET_MY_NEW_NOTIFICATIONS_COUNT,
    payload: 0,
  });
}

export async function viewNotification(engagementId: string) {
  try {
    const result = await Axios({
      service: "engagement",
      method: "post",
      url: `/monitorings/${engagementId}`,
      data: {
        type: "visualized",
      },
    });

    return result;
  } catch (error) {
    ErrorBoundary.captureException(error);
    throw new Error(error);
  }
}

export async function viewNotifications({
  engagementIds,
}: {
  engagementIds: Array<string>;
}) {
  try {
    const result = await Promise.all(
      engagementIds?.map(async (_id: string) => await viewNotification(_id))
    );

    return result;
  } catch (error) {
    ErrorBoundary.captureException(error);
    throw new Error(error);
  }
}

export async function clickNotification(engagementId: string) {
  try {
    const result = await Axios({
      service: "engagement",
      method: "post",
      url: `/monitorings/${engagementId}`,
      data: {
        type: "open",
      },
    });

    return result;
  } catch (error) {
    ErrorBoundary.captureException(error);
    throw new Error(error);
  }
}

export async function getTotalNewNotifications(dispatch: Dispatch<IActions>) {
  try {
    const result = await Axios({
      service: "engagement",
      method: "get",
      url: `/feeds/count`,
      data: {},
    });

    const newNotifications =
      typeof result?.data?.value?.value === "number"
        ? result.data.value.value
        : 0;

    if (newNotifications) {
      dispatch({
        type: EActions.GET_MY_NEW_NOTIFICATIONS_COUNT,
        payload: newNotifications,
      });
    }

    return newNotifications;
  } catch (error) {
    ErrorBoundary.captureException(error);
    throw new Error(error);
  }
}

export async function answerNotification({
  campaignId,
  react,
}: {
  campaignId: string;
  react: number;
}) {
  try {
    const result = await Axios({
      service: "engagement",
      method: "post",
      url: `/monitorings/${campaignId}`,
      data: {
        react,
        type: "react",
      },
    });

    return result?.data?.value?.value;
  } catch (error) {
    ErrorBoundary.captureException(error);
    throw new Error(error);
  }
}

export async function commentaryNotification({
  campaignId,
  commentary,
}: {
  campaignId: string;
  commentary: string;
}) {
  try {
    const result = await Axios({
      service: "engagement",
      method: "post",
      url: `/monitorings/${campaignId}`,
      data: {
        commentary,
        type: "commentary",
      },
    });

    return result?.data?.value?.value;
  } catch (error) {
    ErrorBoundary.captureException(error);
    throw new Error(error);
  }
}

export async function answerEnps({
  campaignId,
  value,
  commentary,
}: {
  campaignId: string;
  value: number;
  commentary?: string;
}) {
  try {
    const result = await Axios({
      service: "engagement",
      method: "post",
      url: `/monitorings/${campaignId}`,
      data: {
        type: "enps",
        enps: value,
        commentary,
      },
    });

    return result?.data?.value?.value;
  } catch (error) {
    ErrorBoundary.captureException(error);
    throw new Error(error);
  }
}

export function setNotificationIdSelected(
  notificationId: string,
  dispatch: Dispatch<IActions>
) {
  dispatch({
    type: EActions.SET_NOTIFICATION_ID_SELECTED,
    payload: notificationId,
  });
}

export async function reactNotification(engagementId: string, like: number) {
  try {
    const result = await Axios({
      service: "engagement",
      method: "post",
      url: `/monitorings/${engagementId}`,
      data: {
        type: "like",
        like: like,
      },
    });

    return result;
  } catch (error) {
    ErrorBoundary.captureException(error);
    throw new Error(error);
  }
}

export async function getNotificationReactions(
  dispatch: Dispatch<IActions>,
  engagementId: string
) {
  try {
    dispatch({
      type: EActions.SYSTEM_LOADING_START,
      payload: "getNotificationReactions",
    });

    const result = await Axios({
      service: "engagement",
      method: "get",
      url: `/engagements/${engagementId}/likes`,
      data: {},
    });

    const totalLikes = result?.data?.value?.value?.totalLikes || 0;
    const likes = result?.data?.value?.value?.likes || {};

    dispatch({
      type: EActions.SYSTEM_LOADING_END,
      payload: "getNotificationReactions",
    });

    return {
      total: totalLikes,
      likes,
    };
  } catch (error) {
    ErrorBoundary.captureException(error);
    throw new Error(error);
  }
}

export async function getContractByCompany(dispatch: Dispatch<IActions>) {
  try {
    dispatch({
      type: EActions.SYSTEM_LOADING_START,
      payload: "getContractByCompany",
    });
    const {
      data: { value },
      status,
    } = await Axios({
      service: "contractManager",
      method: "get",
      url: `sales/contracts`,
    });

    dispatch({
      type: EActions.SYSTEM_LOADING_END,
      payload: "getContractByCompany",
    });

    return value;
  } catch (error) {
    ErrorBoundary.captureException(error);
    throw new Error(error);
  }
}

export async function UpdateContractStatus(
  dispatch: Dispatch<IActions>,
  _id: string,
  body: any
) {
  try {
    dispatch({
      type: EActions.SYSTEM_LOADING_START,
      payload: "UpdateContractStatus",
    });
    const {
      data: { value },
      status,
    } = await Axios({
      service: "contractManager",
      method: "put",
      url: `/temp/contracts/${_id}/update`,
      data: body,
    });

    dispatch({
      type: EActions.SYSTEM_LOADING_END,
      payload: "UpdateContractStatus",
    });

    return value;
  } catch (error) {
    ErrorBoundary.captureException(error);
    throw new Error(error);
  }
}
