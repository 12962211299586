import styled from "styled-components";

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => `${theme.spacings.xs3} ${theme.spacings.xs}`};
`;

const StyledRightContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs2};
`;

const StyledLeftContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs2};
`;

export { StyledContainer, StyledRightContainer, StyledLeftContainer };
