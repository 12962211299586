import {
  Button,
  LinkButton,
  Modal,
  useMediaQuery,
} from "@flash-tecnologia/hros-web-ui-v2";
import { Content, Footer, StyledIconButton } from "./base-modal.styles";

interface BaseModalProps {
  open: boolean;
  onClose?: () => void;
  onBack?: () => void;
  onBackText?: string;
  onNext: () => void;
  onNextText: string | React.ReactNode;
  loading?: boolean;
  children: React.ReactNode;
}

export const BaseModal = ({
  open,
  onClose,
  onBack,
  onBackText,
  onNext,
  onNextText,
  loading,
  children,
}: BaseModalProps) => {
  const isCompactModal = useMediaQuery("max-height: 750px");

  return (
    <Modal.Root
      open={open}
      size={isCompactModal ? "full" : "xs"}
      // Quick-win: remove onClose from modal outside-click
      // until fixed in DS project
      onClose={() => null}
      showClose={false}
      // TODO: Remove after the new modal be implemented in DS
      style={{ maxWidth: 480, margin: "0 auto" }}
    >
      <>
        {onClose && (
          <StyledIconButton
            variant="line"
            size="small"
            icon="IconX"
            onClick={onClose}
          />
        )}
        <Content>{children}</Content>
        <Footer style={{ justifyContent: onBack ? "space-between" : "center" }}>
          {onBack && (
            <LinkButton
              variant="default"
              style={{ alignSelf: "center" }}
              disabled={loading}
              onClick={onBack}
            >
              {onBackText || "Voltar"}
            </LinkButton>
          )}
          <Button
            size="large"
            variant="primary"
            loading={loading}
            onClick={onNext}
            sx={{ minWidth: 200 }}
          >
            {onNextText}
          </Button>
        </Footer>
      </>
    </Modal.Root>
  );
};
