import { Icon } from "../Icon";
import { Container, Title } from "./styles";
import { HeaderProps } from "./types";

const Header = ({ title, onClose }: HeaderProps) => {
  return (
    <Container>
      <Title variant="headline8">{title}</Title>
      <Icon
        name="IconX"
        color="var(--color-neutral-dark4)"
        borderColor="var(--color-neutral-light2)"
        onClick={onClose}
      />
    </Container>
  );
};

export { Header };
