import styled from "styled-components";
import {
  Typography,
  LinkButton,
  Button,
} from "@flash-tecnologia/hros-web-ui-v2";

export const ContainerStyled = styled.div<{ visible?: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 647px;
  max-width: 100%;
  box-shadow: 0px 12px 24px rgba(21, 25, 28, 0.1);
  z-index: 4;
  background-color: #ffffff;

  overflow-y: auto;
  margin-right: ${({ visible }) => (visible ? 0 : "-100%")};
  transition: 0.4s;

  @media screen and (max-width: 647px) {
    width: 100%;
  }
`;

export const HeaderStyled = styled.header`
  display: flex;

  justify-content: space-between;
  padding: 56px 30px 0px 72px;

  @media screen and (max-width: 647px) {
    padding: 46px 45px 0px 45px;
  }
  @media screen and (max-width: 420px) {
    padding: 46px 25px 0px 25px;
  }
`;

export const TitleText = styled(Typography)`
  && {
    word-break: normal;
    width: 60%;

    @media screen and (max-width: 647px) {
      width: 50%;
    }
    @media screen and (max-width: 420px) {
      width: 80%;
      font-size: 16px;
      line-height: 20px;
    }
  }
`;
export const ContentText = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.neutral[50]};
    word-break: normal;
    width: 65%;
  }

  @media screen and (max-width: 420px) {
    width: 100%;
    font-size: 14px;
    line-height: 15px;
  }
`;
export const IconContainer = styled.div`
  display: flex;
  width: 35px;
  height: 35px;
  position: relative;
  top: -30px;
  border: 1px solid #ededee;
  justify-content: center;
  align-items: center;
  border-radius: 20px;

  &:hover {
    background-color: #ededee;
    cursor: pointer;
  }

  @media screen and (max-width: 647px) {
    top: -10px;
  }
`;

export const HeaderText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 90%;
`;

export const CardContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;

  padding: 32px 72px 0px 72px;

  @media screen and (max-width: 647px) {
    padding: 40px 45px 0px 45px;
  }

  @media screen and (max-width: 420px) {
    padding: 40px 25px 0px 25px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  margin-top: 112px;
  margin-bottom: 40px;
  padding-right: 80px;

  @media screen and (max-width: 647px) {
    padding-right: 45px;
  }
  @media screen and (max-width: 420px) {
    padding-right: 25px;
    margin-top: 40px;
    margin-bottom: 40px;
  }
`;

export const LinkButtonStyled = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledButton = styled(Button)`
  && {
    &.MuiButtonBase-root.size-large {
      padding: 20px 53px;
    }
  }
`;

export const Divisor = styled.div`
  height: 2px;
  margin: 0px 72px 40px 72px;
  background-color: #ebe6e9;

  @media screen and (max-width: 647px) {
    margin: 0px 45px 40px 45px;
  }
  @media screen and (max-width: 420px) {
    margin: 0px 25px 40px 25px;
  }
`;
