import { clickNotification, getEmployeeNotificationDetail } from "@/api";
import { Context } from "@/context";
import { setEventTracking } from "@/utils";
import {
  Icons,
  LinkButton,
  Skeleton,
  Typography,
  dayjs,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SectionLoading from "../../../SectionLoading";
import { Icon } from "../Icon";
import {
  Date,
  DateContainer,
  IconContainer,
  TextWithHtml,
  Title,
} from "../Notification/styles";
import { ReactForm } from "../ReactForm";

import {
  BackButton,
  BackButtonText,
  Container,
  DetailContainer,
  FlexBetween,
  Header,
  Image,
  ImageContainer,
  MessageContainer,
  SkeletonContainer,
  TitleCampaignAside,
  TitleCampaignContainer,
} from "./styles";
import { NotificationDetailProps } from "./types";

import { sanitizeHtml } from "@/utils/sanitize-html";
import { useTheme } from "styled-components";
import { Modal } from "./components/Modal";

const NotificationDetail = ({
  _id,
  visible,
  onClickBackButton,
}: NotificationDetailProps) => {
  const theme = useTheme();

  const [t] = useTranslation("translations", {
    keyPrefix: "notification",
  });

  const { dispatch, notificationDetail } = useContext(Context);

  const communication = notificationDetail?.communication;
  const count = notificationDetail?.count;
  const reaction = notificationDetail?.reaction || 0;

  const [openPdfModal, setOpenPdfModal] = useState<boolean>(false);

  const capitalize = (text: string) => {
    const capitalizedText = text.length
      ? text.slice(0, 1).toUpperCase() +
        text.slice(1, text.length).toLowerCase()
      : text;

    return capitalizedText;
  };

  useEffect(() => {
    if (_id) {
      (async () => {
        await getEmployeeNotificationDetail({
          notificationId: _id,
          dispatch,
        });
      })();
    }
  }, [_id]);

  const reactionsCount = [
    count?.liked || 0,
    count?.loved || 0,
    count?.support || 0,
    count?.congratulations || 0,
  ];

  const total = reactionsCount.reduce((acc, curr) => acc + curr, 0);

  return (
    <Container visible={visible}>
      <Header>
        <BackButton onClick={onClickBackButton}>
          <Icons
            name="IconArrowLeft"
            size={24}
            color="var(--color-neutral-dark1)"
          />
          <BackButtonText variant="headline8">
            {t("detailHeaderTitle")}
          </BackButtonText>
        </BackButton>
      </Header>

      <SectionLoading
        url={["getNotificationReactions", "getEmployeeNotificationDetail"]}
      >
        {({ loading }) =>
          loading ? (
            <SkeletonContainer>
              <Skeleton variant="rounded" height={350} />
              <Skeleton variant="rounded" height={50} />
            </SkeletonContainer>
          ) : (
            <DetailContainer>
              <TitleCampaignContainer>
                <IconContainer>
                  <Icon
                    name="IconWallet"
                    color="var(--color-secondary-dark5)"
                    fill="var(--color-secondary-light2)"
                    backgroundColor="var(--color-secondary-light2)"
                  />
                </IconContainer>
                <TitleCampaignAside>
                  <Title variant="body3">{communication?.title}</Title>
                  <DateContainer>
                    <Date variant="caption">
                      {dayjs(communication?.sendDate).format(
                        "DD/MM/YYYY HH:mm"
                      )}
                    </Date>
                  </DateContainer>
                </TitleCampaignAside>
              </TitleCampaignContainer>

              {communication?.message ? (
                <MessageContainer>
                  <TextWithHtml
                    dangerouslySetInnerHTML={{
                      __html: sanitizeHtml(communication?.message),
                    }}
                    style={{ marginTop: "8px" }}
                  />
                </MessageContainer>
              ) : null}

              <FlexBetween>
                {communication?.link?.url ? (
                  <LinkButton
                    variant="default"
                    onClick={async () => {
                      if (communication?.link?.url) {
                        await clickNotification(_id);
                        setEventTracking(
                          "employee_engagement_companyfeed_accessnow_clicked"
                        );
                        window.open(communication?.link?.url, "_blank");
                      }
                    }}
                  >
                    <Typography
                      variant="caption"
                      variantColor={theme.colors.primary}
                      style={{ fontWeight: 700 }}
                    >
                      {capitalize(communication?.link?.label)}
                    </Typography>
                  </LinkButton>
                ) : null}

                {communication?.pdfFile ? (
                  <>
                    <LinkButton
                      variant="default"
                      onClick={() => {
                        setOpenPdfModal(true);
                      }}
                    >
                      <Typography
                        variant="caption"
                        variantColor={theme.colors.primary}
                        style={{ fontWeight: 700 }}
                      >
                        Visualizar PDF
                      </Typography>
                    </LinkButton>
                  </>
                ) : (
                  <></>
                )}
              </FlexBetween>

              {communication?.imageUrl ? (
                <ImageContainer>
                  <Image src={communication?.imageUrl} alt={t("image")} />
                </ImageContainer>
              ) : null}

              {communication?.like ? (
                <ReactForm
                  totalLikes={total}
                  reactionsCount={reactionsCount}
                  engagementId={_id}
                  reaction={reaction}
                />
              ) : null}
            </DetailContainer>
          )
        }
      </SectionLoading>

      <Modal
        open={openPdfModal}
        onClose={() => setOpenPdfModal(false)}
        pdfUrl={communication?.pdfFile}
      />
    </Container>
  );
};

export { NotificationDetail };
