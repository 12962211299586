import { useContext, useState } from "react";

import { setNotificationIdSelected } from "@/api";
import { Context } from "@/context";
import { setEventTracking } from "@/utils";
import { sanitizeHtml } from "@/utils/sanitize-html";
import { Icons, dayjs } from "@flash-tecnologia/hros-web-ui-v2";
import { useTranslation } from "react-i18next";
import { NotificationProps } from "../../types";
import { Icon } from "../Icon";
import {
  Button,
  ButtonContainer,
  ContentContainer,
  Date,
  DateContainer,
  IconContainer,
  MainContainer,
  Space,
  Text,
  TextWithHtml,
  Title,
} from "./styles";

import { NotificationDetail } from "../NotificationDetail";
import { useNavigate } from "react-router-dom";

const Notification = ({
  _id,
  title,
  description,
  date,
  type,
  message,
  visualized,
  responded,
}: NotificationProps) => {
  const navigate = useNavigate();
  const { dispatch, notificationIdSelected } = useContext(Context);

  const [t] = useTranslation("translations", {
    keyPrefix: "notification",
  });

  const [detailVisible, setDetailVisible] = useState<boolean>(false);

  return (
    <MainContainer visualized={visualized}>
      {detailVisible && notificationIdSelected == _id && (
        <NotificationDetail
          _id={notificationIdSelected}
          visible={detailVisible}
          onClickBackButton={() => setDetailVisible(!detailVisible)}
        />
      )}
      <IconContainer>
        <Icon
          name="IconWallet"
          color="var(--color-secondary-dark5)"
          fill="var(--color-secondary-light2)"
          backgroundColor="var(--color-secondary-light2)"
        />
      </IconContainer>
      <ContentContainer>
        <Title variant="body3">{title}</Title>
        <DateContainer>
          <Date variant="caption">{dayjs(date).format("DD/MM/YYYY")}</Date>
          <Space />
          <Date variant="caption">{dayjs(date).format("HH:mm")}</Date>
        </DateContainer>
        {message && type === "communication" ? (
          <TextWithHtml
            dangerouslySetInnerHTML={{ __html: sanitizeHtml(message) }}
            style={{ marginTop: "8px" }}
          />
        ) : null}
        {description && ["research", "enps"]?.includes(type) ? (
          <Text variant="body4" style={{ fontWeight: 400, marginTop: "8px" }}>
            {description}
          </Text>
        ) : null}
        {["research", "enps"]?.includes(type) ? (
          <>
            <ButtonContainer
              onClick={() => {
                setEventTracking(
                  "employee_engagement_companyfeed_answer_clicked"
                );
                navigate(`/engagement/survey/answer/0/${_id}`);
              }}
            >
              <Button variant="caption">
                {responded
                  ? t("researchButtonLabelAnswered")
                  : t("researchButtonLabel")}
              </Button>
              <Icons
                name="IconArrowRight"
                size={15}
                color="var(--color-secondary-dark5)"
              />
            </ButtonContainer>
          </>
        ) : (
          <ButtonContainer
            onClick={() => {
              setEventTracking(
                "employee_engagement_companyfeed_viewdetails_clicked"
              );
              setNotificationIdSelected(_id, dispatch);
              setDetailVisible(!detailVisible);
            }}
          >
            <Button variant="caption">{t("detailButton")}</Button>
            <Icons
              name="IconArrowRight"
              size={15}
              color="var(--color-secondary-dark5)"
            />
          </ButtonContainer>
        )}
      </ContentContainer>
    </MainContainer>
  );
};

export { Notification };
