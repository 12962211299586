import { useMemo, useState } from "react";
import * as SC from "./styled";
import { MenuItem } from "@mui/material";
import CompanyItem from "../CompanyItem";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import { ShapeIcon, Typography } from "@flash-tecnologia/hros-web-ui-v2";

interface CompaniesListProps {
  companies: {
    onClick: () => void;
    company: { id: string; name: string; registrationNumber: string };
  }[];
}

export const CompaniesList = ({ companies }: CompaniesListProps) => {
  const { selectedCompany } = useSelectedCompany();
  const [search, setSearch] = useState("");

  const filteredCompanies = useMemo(() => {
    return companies
      .filter(({ company }) =>
        company.name.toLowerCase().includes(search.toLowerCase())
      )
      .map(({ company, onClick }) => (
        <MenuItem onClick={onClick}>
          <CompanyItem
            key={`item-company-${company.registrationNumber}`}
            active={company.id === selectedCompany?.id}
            registrationNumber={company.registrationNumber}
            name={company.name}
          />
        </MenuItem>
      ));
  }, [companies, selectedCompany, search]);

  const isSearching = search.length > 0;
  const hasResults = filteredCompanies.length > 0;

  return (
    <SC.Container>
      {companies.length > 3 && (
        <SC.SearchInputContainer>
          <SC.SearchIcon
            name="IconSearch"
            size={16}
            strokeWidth="3"
            color="var(--color-neutral-50)"
          />
          <SC.SearchInput
            placeholder="Buscar empresa"
            variant="standard"
            InputProps={{ disableUnderline: true }}
            onKeyDown={(e) => e.stopPropagation()}
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
        </SC.SearchInputContainer>
      )}
      {isSearching && hasResults && (
        <SC.SearchResultsText
          variant="caption"
          variantColor="var(--color-neutral-40)"
        >
          Resultados para a sua busca
        </SC.SearchResultsText>
      )}
      {isSearching && !hasResults && (
        <SC.SearchEmptyState>
          <ShapeIcon name="IconListSearch" variant="neutral" size={32} />
          <Typography
            variant="body4"
            weight={700}
            variantColor="var(--color-neutral-40)"
            style={{ padding: "0 24px" }}
          >
            Não encontramos resultados para a sua busca
          </Typography>
          <SC.SearchResultsText
            variant="caption"
            variantColor="var(--color-neutral-40)"
            style={{ padding: "0 12px" }}
          >
            Verifique se o texto digitado corresponde ao nome de alguma empresa.
          </SC.SearchResultsText>
        </SC.SearchEmptyState>
      )}
      {filteredCompanies}
    </SC.Container>
  );
};
